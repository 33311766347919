
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome.scss";

// Font Awesome
@import "node_modules/chosen-js/chosen";

// Custom
@import "search";



.enlarge {
	position: relative; /*allows precise positioning of the popup image when used with position:absolute - see support section */
	z-index: 0; /*resets the stack order of the list items - we'll increase in step 4. See support section for more info*/
}
.enlarge span{
	position:absolute; /*see support section for more info on positioning*/
	left: -9999px; /*moves the span off the page, effectively hidding it from view*/
	display: none;
}

 .enlarge:hover{
	z-index: 50; /*places the popups infront of the thumbnails, which we gave a z-index of 0 in step 1*/
	cursor:pointer; /*changes the cursor to a hand*/
}
/***We bring the large image back onto the page by reducing left from -9999px (set in step 2) to figures below***/
.enlarge:hover span { /*positions the <span> when the <li> (which contains the thumbnail) is hovered*/
	top: -150px; /*the distance from the bottom of the thumbnail to the top of the popup image*/
	left: -370px; /*distance from the left of the thumbnail to the left of the popup image*/
	padding: 10px;
	background: #fff;
	border: 1px solid #ccc;
	/*add a drop shadow to the frame*/
	-webkit-box-shadow: 0 0 20px rgba(0,0,0, 0.6);
	-moz-box-shadow: 0 0 20px rgba(0,0,0, 0.6);
	box-shadow: 0 0 20px rgba(0,0,0, 0.6);
	display: inherit;
}

.container--photo-index {
	margin-bottom: 250px;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}

a {
	color: #0097F2;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #fff;
  border-top: #d3e0e9 1px solid;
}

.footer .container .text-muted {
  margin: 19px 0;
}

/* Photo Grid */
.panel-photogrid {

  .row {
  	padding-bottom: 15px;
  	padding-top: 15px;
  }

  .row:first-child {
  	padding-top: 0;
  }

  .row:last-child {
  	padding-bottom: 0;
  }
}

/* Operator Index Page */
.filter-test {

    &__landscape,
    &__portrait,
    &__square {
    	background-size: cover;
    }

    &__landscape {
    	background-image: url('/images/testcard-landscape.jpg');
    }
    &__portrait {
    	background-image: url('/images/testcard-portrait.jpg');
    }
    &__square {
    	background-image: url('/images/testcard-square.jpg');
    }
}

.panel-info {
	font-size: 50px;
  color: #0097F2;
}

th{
  color: #073763;
}

/* Navbar */

.navbar-logo {
	padding: 0;
}

.navbar-default .navbar-nav>li>a {
  color: white;
}

.navbar-default .navbar-nav>li>a:hover {
	color: #0097F2;
}

.navbar-default {
	background-color: #073763;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
	background-color: #073763;
	color: white;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>li>a:focus {
	color: white;
}

.table-hover tr{
	cursor: pointer;
}

.media-low {
  color: red;
}

.positive-month {
  color: green;
}

.negative-month {
  color: red;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}